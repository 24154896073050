
import { defineComponent, PropType } from "vue";

type Item = {
  title: string;
  value: string;
  titleStyle?: string;
  valueStyle?: string;
};

export default defineComponent({
  name: "MobileInvoiceSuccess",
  props: {
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
    date: {
      required: true,
      type: String,
    },
    time: {
      required: true,
      type: String,
    },
  },
});
